.container {
	border-left: 1px solid #c1c7cd;
	padding: 0 16px;

	.fileHeader {
		font-weight: 700;
		font-size: 23px;
		display: flex;
		align-items: center;

		img {
			margin-right: 10px;
		}
	}

	.section {
		padding: 16px 0;

		.actions {
			padding-top: 16px;
			margin-left: 30px;
			color: #00539a;

			.icon {
				margin-right: 10px;
			}

			svg {
				&:hover {
					cursor: pointer;
				}
			}
		}

		.title {
			font-size: 16px;
			padding-bottom: 16px;
		}

		.previewImg {
			width: 100%;
		}

		.properties {
			font-size: 14px;

			.label {
				color: #878d96;
			}
		}
	}
}
