.formContainer {
	margin: 0 auto;
	max-width: 400px;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
	padding: 48px;

	.forgotPass {
		width: 100%;
		text-align: right;
		display: block;
		padding-top: 5px;
	}

	.formLabel {
		span {
			font-size: 12px;
		}
	}
	
	.loginBtn {
		width: 100%;
		font-size: 16px;
	}

	.states {
		width: 100%;
	}

	.fileUploadLabel {
		font-size: 12px;
		padding-bottom: 5px;
		color: #21272A;
		display: flex;
		align-items: center;

		.icon {
			padding-left: 8px;
		}
	}

	.description {
		font-size: 12px;
	}

	.document {
		display: flex;
		align-items: center;
		margin-bottom: 5px;

		p {
			padding: 0 5px;
			font-size: 14px;
			font-weight: bold;
		}
	}

	.finalIcon {
		display: flex;
		justify-content: center;
		
		svg {
			color: #24A148;
		}
	}
}