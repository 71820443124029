.container {
	padding: 16px 32px;

	.section {
		margin-bottom: 16px;
	}

	.dealSelector {
		min-width: 250px;
		height: 40px;
		font-size: 21px;
		line-height: 28px;
		font-weight: bold;
	}

	.periodSelector {
		margin-left: 8px;

		min-width: 250px;
		height: 40px;
		font-size: 21px;
		line-height: 28px;
		font-weight: bold;
	}

	.dataTypeContainer {
		display: flex;
		justify-content: flex-end;

		.inactive {
			color: #878D96;
			border-color: #878D96;
		}
	}

	.kpiGrid {
		border-top: 1px solid #C1C7CD;
		border-bottom: 1px solid #C1C7CD;

		.kpi {
			padding: 16px;
			border-right: 1px solid #C1C7CD;

			&:first-of-type {
				padding-left: 0;
			}
		}

		.simulator {
			padding: 16px;
			button {
				width: 100%;
			}
		}
	}

	.closeSimulator {
		margin-top: 10px;
	}

	.graphContainer {
		position: relative;
		.title {
			font-weight: 600;
			font-size: 16px;
			margin-bottom: 16px;
			margin-top: 0;
		}

		.graphDescription {
			display: flex;
			border-top: 1px solid #e5e5e5;
			margin-bottom: -9px;
			font-size: 12px;

			.left {
				width: calc(30% - 47px);
				border-right: 1px solid #e5e5e5;
				padding: 10px 0 10px 40px;
			}
			.right {
				padding: 10px;
			}
		}
	}

	.tableContainer {
		p.title {
			font-weight: bold;
		}
	}
}

li.markedLabel {
	border-top: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
}