.container {
	border-top: 1px solid #dde1e6;
	border-bottom: 1px solid #dde1e6;

	.formInner {
		padding-bottom: 16px;
		padding-top: 16px;
	}
}

.title {
	font-size: 23px;
	font-weight: bold;
	padding-top: 16px;

	button {
		margin-left: 10px;
	}
}
