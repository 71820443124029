.file {
	.fileName {
		font-weight: 600;
	}

	&:hover {
		cursor: pointer;
	}
}

.fileIcon {
	width: 25px;
}

.user {
	text-decoration: underline;
}

.contextMenu {
	background: #fff;
	padding: 8px 0;
	width: 214px;
	box-shadow: 0px 2px 16px rgba(135, 141, 150, 0.35);

	.item {
		display: flex;
		align-items: center;
		font-size: 16px;
		font-weight: 300;

		&:hover {
			cursor: pointer;
			background: #f2f4f8;
		}

		svg {
			margin-right: 8px;
		}

		.contextItem {
			padding: 8px;
			display: flex;
			width: 100%;
			align-items: center;
		}
	}
}

.editField {
	background: #fff;

	input {
		padding: 8px;
	}
}
