.navigation {
	display: flex;
	font-size: 18px;
	align-items: center;
	color: #121619;
	background-color: #A2A9B0;
	
	div {
		height: 64px;
		padding: 0 32px;
		display: flex;
		align-items: center;

		a {
			text-decoration: none;
			color: #121619;

			height: calc(100% - 4px);
			display: flex;
			justify-content: center;
			align-items: center;

			&.active {
				font-weight: bold;
				border-bottom: 4px solid #00539A;
			}
		}
	}

	.logo {
		background-color: #C1C7CD;
		width: 80px;
		text-align: center;
		justify-content: center;
	}

	.user {
		margin-left: auto;
	}
}