.container {
	max-width: 500px;
	margin: 0 auto;
	text-align: center;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
	padding: 32px;

	h1 {
		margin-top: 64px;
		font-family: 'Roboto Mono';
		margin-bottom: 32px;
	}

	button {
		margin-top: 48px;
		width: 100%;
	}
}
