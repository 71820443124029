.table {
	.value {
		font-size: 14px;
	}

	.footer {
		.dealsTotal {
			p {
				font-weight: bold;
			}

			.cellName {
				min-width: 130px;
			}
		}
	}
}