.navigation {
	display: flex;
	font-size: 16px;
	border-left: 1px solid #C1C7CD;
	margin-top: 16px;
	flex-direction: column;
	
	div {
		height: 50px;
		display: flex;

		a {
			text-decoration: none;
			color: #262626;
			padding-left: 14px;

			height: calc(100% - 4px);
			display: flex;
			justify-content: center;
			align-items: center;

			&.active {
				font-weight: bold;
				font-size: 18px;
				border-left: 4px solid #1192E8;
			}
		}
	}
}