.container {
	padding: 16px 32px;

	.section {
		margin-bottom: 16px;
	}

	.dealSelector {
		min-width: 250px;
		height: 40px;
		font-size: 21px;
		line-height: 28px;
		font-weight: bold;
	}

	.periodSelector {
		margin-left: 8px;

		min-width: 250px;
		height: 40px;
		font-size: 21px;
		line-height: 28px;
		font-weight: bold;
	}

	.dataTypeContainer {
		display: flex;
		justify-content: flex-end;

		.inactive {
			color: #878d96;
			border-color: #878d96;
		}
	}

	// .kpiGrid {
	// 	border-top: 1px solid #c1c7cd;
	// 	border-bottom: 1px solid #c1c7cd;

	// 	.kpi {
	// 		padding: 16px;
	// 		border-right: 1px solid #c1c7cd;

	// 		&:first-of-type {
	// 			padding-left: 0;
	// 		}
	// 	}

	// 	.simulator {
	// 		padding: 16px;

	// 		p {
	// 			margin: 0 0 20px 0;
	// 			font-size: 14px;
	// 		}
	// 		button {
	// 			width: 100%;
	// 		}
	// 	}
	// }

	.kpiGrid {
		// border: 1px solid #c1c7cd;
		// border-bottom: 1px solid #c1c7cd;

		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 0;
		
		.kpi {
			padding: 16px;

			border: 1px solid #c1c7cd;
			margin-left: -1px;
			margin-top: -1px;
		}

		@media screen and (min-width: 768px) {
			grid-template-columns: repeat(5, 1fr);

			.kpi + .kpi {
				border-left: 0px solid #c1c7cd;
			}
		}

		.simulator {
			padding: 16px;

			p {
				margin: 0 0 20px 0;
				font-size: 14px;
			}
			
			button {
				width: 100%;
			}
		}
	}

	.smallKpi {
		margin-top: 32px;
	}

	.description {
		white-space: pre-wrap;
		text-align: justify;
		padding-top: 26px;
	}

	.breadcrumbs {
		div {
			color: #121619;
			font-size: 23px;
			font-weight: 700;
		}

		svg {
			color: #878d96;
		}

		.status {
			font-size: 14px;
			margin-left: 10px;
			margin-right: 10px;
			font-weight: normal;
		}

		.breadcrumb {
			display: flex;
		}
	}

	.videoContainer {
		padding: 47px 16px 16px 16px;
		position: relative;
	}

	.graphContainer {
		position: relative;
	}

	.graphDetails {
		position: relative;
	}
}
