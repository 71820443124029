.formContainter {
	.twoFactorInput {
		letter-spacing: 10px;
		text-align: center;
	
		input {
			padding: 5px;
			font-size: 26px;
			text-align: center;
		}
	}
	
	.description {
		font-size: 12px;
		margin: 0;
	}
	
	.phoneNumber {
		width: 100%;
		
		div {
			border-radius: 0;
		}
	}

	.input {
		border-left: 1px solid red;
	}
	
	.loginBtn {
		width: 100%;
		font-size: 16px;
	}
}

.MuiPhoneNumber-flagButton {
	border-radius: 0;
	border-right: 1px solid black;
}