.bulkContainer {
	position: fixed;
	bottom: 8px;
	background: #fff;
	padding: 16px;
	box-shadow: 0px 0px 6px 1px #c1c7cd;
	width: 100%;
	display: flex;
	align-items: center;
	// max-width: 1000px;

	.title {
		font-weight: bold;
	}

	.actions {
		padding-left: 20px;

		&:hover {
			cursor: pointer;
		}

		svg {
			margin-right: 5px;
		}
	}
}
