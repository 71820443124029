.toast {
	background: #fff;
	color: #000;

	.title {
		font-weight: bold;
		font-size: 14px;
		padding-bottom: 16px;
		border-bottom: 1px solid #c1c7cd;
		display: flex;
	}
	.check {
		color: #198038;
	}

	.error {
		color: #a2191f;
	}

	.file {
		display: flex;
		align-items: center;
		padding-top: 16px;
		font-size: 14px;
		font-weight: 600;

		.filename {
			padding-left: 5px;
		}
	}

	.right {
		margin-left: auto;
	}
}
