.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 600px;
	background-color: #fff;
	box-shadow: 0 0 5px #c1c7cd;
	padding: 0 16px 16px 16px;

	max-height: 90vh;
    overflow: auto;

	.btnGroup {
		display: flex;
		justify-content: flex-end;

		button {
			&:first-of-type {
				margin-right: 10px;
			}
		}
	}

	.title {
		border-bottom: 1px solid #c1c7cd;
	}

	.content {
		padding: 16px 0;
	}
	.breadcrumbs {
		padding-bottom: 16px;
		padding-top: 16px;

		div {
			color: #121619;
			font-size: 18px;
			font-weight: 700;
		}

		svg {
			color: #878d96;
		}

		.breadcrumb {
			&:hover {
				cursor: pointer;
			}
		}
	}

	.iconCell {
		width: 40px;
	}
}

.longDeleteBtn {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 8px;
}
