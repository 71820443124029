.container {
	position: relative;
	padding-bottom: 50px;

	.breadcrumbs {
		padding-bottom: 16px;
		padding-top: 16px;

		div {
			color: #121619;
			font-size: 23px;
			font-weight: 700;
		}

		svg {
			color: #878d96;
		}

		.breadcrumb {
			&:hover {
				cursor: pointer;
			}
		}
	}

	.fileName {
		font-weight: 600;
	}

	.folderName {
		font-weight: 600;

		&:hover {
			cursor: pointer;
		}
	}

	.fileIcon {
		width: 25px;
	}

	.user {
		text-decoration: underline;
	}

	.emptyBin {
		svg {
			margin-left: 10px;
		}
	}
}
