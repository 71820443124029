.editUser {
	.properties {
		font-size: 14px;

		.label {
			color: #878d96;
		}
	}

	.actions {
		margin-top: 32px;
		display: flex;
		align-items: center;

		button {
			height: 36px;
		}

		.right {
			margin-left: auto;

			.cancelBtn {
				margin-right: 10px;
			}
		}
	}
}

.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 400px;
	background-color: #fff;
	box-shadow: 0 0 5px #c1c7cd;
	padding: 0 16px 16px 16px;

	.btnGroup {
		display: flex;
		justify-content: flex-end;

		button {
			&:first-of-type {
				margin-right: 10px;
			}
		}
	}
}
