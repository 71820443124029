.container {
	.breadcrumbs {
		padding-bottom: 16px;
		padding-top: 16px;

		div {
			color: #121619;
			font-size: 23px;
			font-weight: 700;
		}

		svg {
			color: #878d96;
		}

		.breadcrumb {
			&:hover {
				cursor: pointer;
			}
		}
	}

	.fileName {
		font-weight: 600;
	}

	.folderName {
		font-weight: 600;

		&:hover {
			cursor: pointer;
		}
	}

	.fileIcon {
		width: 25px;
	}

	.user {
		text-decoration: underline;
	}
}

.noResult {
	text-align: center;
	font-size: 20px;
	font-weight: 300;

	img {
		margin-top: 50px;
	}

	.title {
		font-weight: bold;
		font-size: 23px;
		margin-bottom: 18px;
		margin-top: 50px;
	}

	.description {
		font-size: 14px;
	}
}
