button.button {
	font-size: 16px;
    padding: 12px;
    line-height: 18px;
    width: 200px;
}
.menu {
	svg {
		padding-right: 10px;
	}
}

.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 400px;
	background-color: #fff;
	box-shadow: 0 0 5px #C1C7CD;
	padding: 0 16px 16px 16px;

	.btnGroup {
		display: flex;
		justify-content: flex-end;
		
		button {
			&:first-of-type {
				margin-right: 10px;
			}
		}
	}
}