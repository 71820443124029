.formContainer {
	margin: 0 auto;
	max-width: 400px;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
	padding: 48px;
	
	.loginBtn {
		width: 100%;
		font-size: 16px;
	}
}