.progress {
	display: flex;
	justify-content: center;
	margin-top: -10px;
    padding-bottom: 24px;

	div {
		border-bottom: 4px solid #DDE1E6;
		width: 32px;
		margin: 0 5px;
		
		&.active {
			border-color: #00539A;
		}
	}
}