.root {
	position: relative;

	&::after {
		content: '';
		height: 80vh;
		width: 100vw;
		position: absolute;
		background-color: #F2F4F8;
		z-index: -1;
		position: absolute;
		top: 0;
		clip-path: polygon(100% 0, 100% 40%, 0 100%, 0 100%, 0 0);
		opacity: .5;
	}

	.container {
		width: 750px;
		background-color: #fff;
		padding-bottom: 2.5rem;    /* Footer height */
		min-height: 100vh;

		.logo {
			padding: 48px;
			text-align: center;
			
			img {
				width: 100px;
			}
		}
		
	}
}