.container {
	text-align: center;
	margin-top: 64px;

	.icon {
		stroke-width: 1px;
		color: #00539a;
	}

	.title {
		font-weight: bold;
		font-size: 23px;
		margin-top: 40px;
	}
}
