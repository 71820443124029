.settingsContainer {
	.title {
		font-weight: bold;
		font-size: 24px;
		display: flex;
		align-items: center;
		padding-bottom: 16px;

		button {
			margin-left: auto;
			margin-right: 16px;
		}

		.closeIcon {
			margin-left: auto;
			color: #31373d;

			&:hover {
				cursor: pointer;
			}
		}
	}

	.container {
		padding-top: 32px;
	}

	.form {
		padding-left: 16px;
		border-left: 1px solid #c1c7cd;

		.title {
			padding-bottom: 32px;
		}
	}

	.userRow {
		&:hover {
			cursor: pointer;
		}
	}
}
