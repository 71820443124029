
.title {
	font-weight: bold;
	padding-bottom: 16px;
}

.container {
	position: relative;
	padding: 0 32px;

	:global(.slick-list) {
		// padding: 0 20px;

		:global(.slick-track) {
			margin-left: 0;
		}
	}
	
	.file {
		display: flex;
		align-items: center;
		border: 1px solid #C1C7CD;
		padding: 14px 8px 14px 8px;
		margin-right: 16px;

		.description {
			padding-left: 8px;
			overflow: hidden;

			.name {
				display: flex;

				p {
					margin: 0 0 5px 0;
					font-weight: bold;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;

					&:hover {
						cursor: pointer;
					}

				}
			}

			span {
				font-size: 12px;
				
				.who {
					text-decoration: underline;
				}
			}
		}
	}
}