.container {
	padding: 16px 32px;

	.section {
		margin-bottom: 16px;
	}

	.dealSelector {
		min-width: 250px;
		height: 40px;
		font-size: 21px;
		line-height: 28px;
		font-weight: bold;
	}

	.periodSelector {
		margin-top: 8px;
		@media screen and (min-width: 768px) {
			margin-top: 0;
			margin-left: 8px;
		}

		min-width: 250px;
		height: 40px;
		font-size: 21px;
		line-height: 28px;
		font-weight: bold;
	}

	.dataTypeContainer {
		display: flex;
		justify-content: flex-end;

		.inactive {
			color: #878d96;
			border-color: #878d96;
		}
	}

	.kpiGrid {
		// border: 1px solid #c1c7cd;
		// border-bottom: 1px solid #c1c7cd;

		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 0;

		

		.kpi {
			padding: 16px;

			border: 1px solid #c1c7cd;
			// margin: -1px;
			margin-left: -1px;
			margin-top: -1px;
			

			// &:first-of-type {
			// 	padding-left: 0;
			// }
		}

		@media screen and (min-width: 768px) {
			grid-template-columns: repeat(5, 1fr);

			.kpi + .kpi {
				border-left: 0px solid #c1c7cd;
			}
		}

		.simulator {
			padding: 16px;

			p {
				margin: 0 0 20px 0;
				font-size: 14px;
			}
			button {
				width: 100%;
			}
		}
	}

	.graphContainer {
		position: relative;

		.title {
			font-weight: 600;
			font-size: 16px;
			margin-bottom: 16px;
			margin-top: 0;
			padding: 0;
		}

		.graphDetails {
			padding: 0;
			position: relative;
		}

		.graphDescription {
			display: flex;
			border-top: 1px solid #e5e5e5;
			margin-bottom: -9px;
			font-size: 12px;

			.left {
				width: calc(30% - 47px);
				border-right: 1px solid #e5e5e5;
				padding: 10px 0 10px 40px;
			}
			.right {
				padding: 10px;
			}
		}
	}
}

li.markedLabel {
	border-top: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
}