.kpi {
	.name {
		color: #878d96;
		font-size: 14px;
		display: flex;
		align-items: center;

		span {
			display: flex;
			padding-left: 5px;
		}
	}

	.value {
		font-size: 32px;
		padding: 8px 0;
		font-weight: bold;
	}

	.smallValue {
		font-size: 23px;
		font-weight: bold;
	}

	.trending {
		font-size: 14px;
		display: none;
		align-items: center;
		font-weight: bold;

		svg {
			padding-right: 5px;
		}

		&.up {
			color: #24a148;
		}

		&.down {
			color: #da1e28;
		}
	}
}
