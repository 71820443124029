button.button {
	background-color: none;
	text-transform: none;
	color: #121619;
	font-size: 14px;
}
.menu {
	svg {
		padding-right: 10px;
	}
}