.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 900px;
	background-color: #fff;
	box-shadow: 0 0 5px #C1C7CD;

	// .btnGroup {
	// 	display: flex;
	// 	justify-content: flex-end;
		
	// 	button {
	// 		&:first-of-type {
	// 			margin-right: 10px;
	// 		}
	// 	}
	// }

	.title {
		display: flex;
		padding: 16px;
		border-bottom: 1px solid #C1C7CD;
		align-items: center;
		font-weight: bold;

		.closeBtn {
			margin-left: auto;

			&:hover {
				cursor: pointer;
			}
		}
		.fileName {
			padding-left: 10px;
		}
	}

	.actions {
		color: #00539A;
		padding-left: 20px;

		svg {
			margin-right: 5px;
			&:hover {
				cursor: pointer;
			}
		}
	}

	.content {
		padding: 16px;
		background: #F4F4F4;
		position: relative;
		max-height: 500px;
		overflow: scroll;

		:global(.pdf-canvas) {
			display: flex;
			justify-content: center;
		}
	}
}