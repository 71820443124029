.dropzone {
	border: 1px dashed #21272A;
	position: relative;
	padding: 5px;

	.files {
		.uploadedFile {
			width: 100%;
   			margin-bottom: 1px;
			display: flex;
			justify-content: left;
		}
	}

	.description {
		margin: 0;
    	padding: 20px 10px;
		display: flex;
		align-items: center;

		.icon {
			padding-right: 10px;
		}

		a {
			padding-right: 10px;
		}
	}
}