.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 2.5rem; /* Footer height */

	.links {
		padding: 0 8px 0 8px;
		font-size: 14px;
		display: flex;
		justify-content: center;

		.item {
			padding: 10px;
			font-weight: bold;

			.contact {
				color: black;
				text-decoration: none;
			}
		}
	}
}
