.cell {
	.textField {
		input {
			padding: 4px 5px;
		}
	}
}

.name {
	font-weight: bold;
	color: #00539A;

	&:hover {
		cursor: pointer;
	}
}