.message {
	font-size: 24px;
	font-weight: 300;
	text-align: center;
}

.finalIcon {
	display: flex;
	justify-content: center;
	
	svg {
		color: #24A148;
	}
}