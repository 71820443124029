.formContainer {
	margin: 0 auto;
	max-width: 400px;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
	padding: 48px;

	.forgotPass {
		width: 100%;
		text-align: right;
		display: block;
		padding-top: 5px;
	}

	.keepMeInContainer {
		padding-top: 5px !important;
	}
	
	.loginBtn {
		width: 100%;
		font-size: 16px;
	}
}